.root, html, body {
  height: 100%;
  margin: 0;
}

body {
	margin-left: 16px;
	margin-right: 16px;
}

.root {
  height: 100%;
  min-height: 100%;
	/* display: flex;
	flex-direction: column; */
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

input:focus {
	border-width:100
}